import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap, map } from "rxjs/operators";
import { of, Observable } from "rxjs";
import { GlobalService } from '../../services/global.service';
import { LoginService } from '../login/login.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../services/config.service';
import { environment } from 'src/environments/environment';
import { dependentLoginVerfiyRoutes, SSOLoginRedirectToDashboardConditions, userRoles } from 'src/app/shared/models/constants';

@Injectable({
    providedIn: 'root'
})

export class AuthService {
    user: any = {};
    constructor(
        private router: Router,
        private global: GlobalService,
        private loginService: LoginService,
        private toastr: ToastrService,
        private http: HttpClient,
        private configService: ConfigService
    ) { }

    getToken(): string {
        return localStorage.getItem('AUTH_TOKEN');
    }

    deleteToken() {
        localStorage.clear();
        this.router.navigate(['/login']);
    }

    setToken(token) {
        localStorage.setItem('AUTH_TOKEN', token);
    }

    checkUserSession(loggedInStatus, routeUrl): boolean {
        if (this.isLoggedIn() === loggedInStatus) {
            this.router.navigate([routeUrl]);
            return false;
        }
        return true;
    }

    isLoggedIn(): boolean {
        return this.getToken() ? true : false;
    }

    checkUserRoleSession(loggedInStatus, routeUrl, routeRole): boolean {
        this.user = this.getLocalStorageUser();
        if (this.isLoggedIn() === loggedInStatus) {
            this.router.navigate([routeUrl]);
            return false;
        } else {
            let status = false;
            switch (routeRole) {
                case "Employee":
                    status = this.checkEmployeeAccess();
                    break;

                case "Employer":
                    status = this.checkEmployerAccess();
                    break;

                case "Carrier":
                    status = this.checkCarrierAccess();
                    break;

                case "Agent":
                    status = this.checkAgentAccess();
                    break;

                case "Organisation":
                    status = this.checkOrganisationAccess();
                    break;

                case "Dependent":
                    status = this.checkDependentAccess();
                    break;


                default:
                    status = this.checkEmployerAccess();
                    break;
            }
            return status;
        }
    }

    checkEmployerAccess() {
        if ((this.user['role'] == "Employee" && this.user['isAdmin'] == 1) || (this.user['role'] == "Employer")) {
            return true;
        } else {
            this.router.navigate(["dashboard"]);
            return false;
        }
    }

    checkEmployeeAccess(): boolean {
        if (this.user['role'] == "Employee" && this.user['isAdmin'] == 0) {
            return true;
        } else {
            this.router.navigate(["dashboard"]);
            return false;
        }
    }

    checkCarrierAccess(): boolean {
        if (this.user['role'] == "Carrier") {
            return true;
        }
        this.router.navigate(["dashboard"]);
        return false;
    }

    checkAgentAccess(): boolean {
        if (this.user['role'] == "Agent") {
            return true;
        }
        this.router.navigate(["dashboard"]);
        return false;
    }

    checkOrganisationAccess(): boolean {
        if (this.user['role'] == "Organisation") {
            return true;
        }
        this.router.navigate(["dashboard"]);
        return false;
    }

    checkDependentAccess(): boolean {
        if (this.user['role'] == "Dependent") {
            return true;
        } else {
            this.router.navigate(["dashboard"]);
            return false;
        }
    }

    getLocalStorageUser() {
        return this.global.user;
    }

    /**
     * This funtion is use to Show verify passcode page.
     */
    checkPasscodeVerified(url) {
        const { passcodeVerified } = this.global?.user ?? {};
        const token = this.getToken();
        if ((token && !passcodeVerified && !localStorage.getItem('passcodeVerified')) || (url === '/dashboard' && !localStorage.getItem('passcodeVerified'))) {
            this.router.navigate(['/passcode-verification']);
        }
    }

    /**
     * This funtion is use to Handles the change password redirection based on SSO and first login status.
     * This function handles the change password redirection based on SSO and first login status.
     * @param {string} url - URL to check for navigation.
     */
    checkFirstLogin(url: string): void {
        const { firstLogin, role, passcodeVerified } = this.global?.user ?? {};

        // Check if the user is logged in via SSO
        if (this.isSSOLogin()) {

            // Redirect SSO users to the dashboard based on conditions
            if (SSOLoginRedirectToDashboardConditions.includes(url.split('/')[1]))
                this.router.navigate(['dashboard']);

        } else if (firstLogin) {
            let redirectUrl = '/change-password';

            this.router.navigate([redirectUrl]);

        } else if (url === '/change-password') {
            this.router.navigate(['dashboard']);
        }
    }

    checkLogin(): Observable<boolean> {
        let token = this.getToken();
        return this.loginService.checkLogin(token).pipe(
            tap((res) => {
                if (res.status)
                    this.global.user = res['data'];
                else
                    this.toastr.warning(res.message);
            }),
            map((res) => res.status ? true : false, error => false)
        );
    }

    forgotPassword(body) {
        const apiUrl = `${this.configService.configs.apiBaseUrl}/${this.configService.configs.version}/${environment.forgotPassword}`;
        return this.http.post(apiUrl, body);
    }

    isSSOLogin(): boolean {
        return JSON.parse(localStorage.getItem('isSSOLogin') || 'false');
    }

}
