import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GuestGuard } from './core/guards/guest.guard';

const routes: Routes = [
    //Private
    { path: '', loadChildren: () => import('./modules/private/private.module').then(m => m.PrivateModule) },
    //{ path: 'carrier-employee', loadChildren: () => import('./modules/private/carrier/employee/employee.module').then(m => m.EmployeeModule) },
    //{ path: 'carrier-group-bill', loadChildren: () => import('./modules/private/carrier/group-bill/carrier-group-bill.module').then(m => m.CarrierGroupBillModule) },
    //{ path: 'employer', loadChildren: () => import('./modules/private/organisation/employer/employer.module').then(m => m.EmployerModule) },

    //Public
    { path: 'login', loadChildren: () => import('./modules/public/login/login.module').then(m => m.LoginModule), canActivate: [GuestGuard] },
    { path: 'login/employee', redirectTo: 'login/member', pathMatch: 'full' },
    { path: 'login/member', loadChildren: () => import('./modules/public/login-employee/login.module').then(m => m.LoginModule), canActivate: [GuestGuard] },
    { path: 'check-login/:token', loadChildren: () => import('./modules/public/check-login/check-login.module').then(m => m.CheckLoginModule) },
    { path: 'forgot-password', loadChildren: () => import('./modules/public/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
    { path: 'member-payments/pid/:pid', loadChildren: () => import('./modules/public/member-payments/member-payments.module').then(m => m.MemberPaymentsModule) },
    { path: 'thank-you', loadChildren: () => import('./modules/public/thank-you/thank-you.module').then(m => m.ThankYouModule) },
    { path: 'quick-enroll', loadChildren: () => import('./modules/public/quick-enroll/quick-enroll.module').then(m => m.QuickEnrollModule) },
    { path: 'passcode-verification', loadChildren: () => import('./modules/public/passcode-verification/passcode-verification.module').then(m => m.PasscodeVerificationModule) },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
