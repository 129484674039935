// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    //Auth
    login: 'login',
    employeeLogin: 'employee/login',
    checkLogin: 'check-login',
    forgotPassword: 'forgot-password',
    getUserDetails: 'users/getUserDetails',
    getDashboardDetails: 'dashboard',
    userManagement: 'user-management/users',
    sso: 'single-sign-on',
    sendPasscode: 'send-portal-passcode',
    sendPasscodeVerify: 'verify-portal-passcode',
    userDetails: 'user-details',

    //Dashboard
    dashboardCompanyInformation: 'company-information',
    dashboardBillingSummary: 'billing-summary',
    dashboardEnrolmentSummary: 'enrolment-summary',
    dashboardPaymentSummary: 'payment-summary',
    dashboardMemberInformation: 'member-information',
    getDashboardEmployeePolicies: 'dashboard-employee-policies',
    dashboardFamilyMember: 'family-member',
    dashboardPolicyDetails: 'policy-details',

    //Employee
    employeePaymentMethods: 'payment-methods-fetch',
    employeeInvoices: 'invoice-list',
    employeePayments: 'payments-list',
    employeePolicyList: 'employee/policy-list',
    employeePaymentsConfiguration: 'member/payment-configurations',
    employeeDefaultPaymentDetails: "employee/payment-details",
    employeeInvoicePayment: "employee/invoice-payment",
    claimList: 'employee/claim-list',
    claimDetails: 'employee/claim-details',
    sendCardByMail: 'email-id-cards',
    employeeClaimsList: 'employee/claims',
    employeeClaimsDetails: 'employee/claim',
    downloadIdCard: 'download-id-card',
    inviteToPortal: 'dependents/app-invite',
    shareClaimsData: 'portal/share-claims-data',

    //Employer
    groupBillsList: 'group-bills-list',
    fetchGroupBillDetails: 'group-bill-details',
    remittanceUpload: 'group-bill/group-bill-remittance-upload',
    downloadGroupBillInvoiceDetails: 'group-bill-invoice-download',
    getBillingGroupRemittanceDate: 'billing-groups-remittance-date',
    employerEnrolmentList: 'employer/enrolment-list',
    employerEnrolmentListDownload: 'employer/enrolment-list-download',
    employerClaimsFundVendors: 'employer/claims-fund-vendors',
    employerClaimsFundList: 'employer/claims-fund-list',
    employerClaimsFundListDownload: 'employer/claims-fund-list-download',
    employerBillingSchedule: 'quick-enroll/billing-groups',
    fetchConfigSettings: 'quick-enroll/config-settings',

    //Organisation
    organisationEmployers: 'organisation/employer-list',
    organisationEmployersEmployee: 'organisation/employer-employee-list',
    organisationAgencies: 'organisation/agency-list',
    organisationAgenciesAgent: 'organisation/agency-agent-list',
    organisationVendors: 'organisation/vendor-list',

    //Carrier
    carrierGroupBillsList: 'carrier/group-bills-list',
    carrierFetchGroupBillDetails: 'carrier/group-bill-details',
    carrierEmployerEmployeeList: 'carrier/employer-employee-list',
    carrierEmployerList: 'carrier/employer-list',
    carrierPastDueInvoices: 'carrier/past-due-invoices',
    carrierPaymentsList: 'carrier/payments-list',
    carrierStatementList: 'carrier/vendor-statement',
    carrierStatementListDownload: 'carrier/vendor-statement-download',
    carrierStatementDetails: 'carrier/vendor-statement-details',
    carrierEnrolmentList: 'carrier/enrolment-list',
    carrierEnrolmentListDownload: 'carrier/enrolment-list-download',
    carrierClaimsFundList: 'carrier/claims-fund-list',
    carrierClaimsFundListDownload: 'carrier/claims-fund-list-download',
    carrierClaimsFundVendors: 'carrier/claims-fund-vendors',
    carrierStatementDetailListDownload: 'carrier/vendor-statement-detail-download',

    //Agent
    agentEmployerList: 'agent/employer-list',
    agentEmployeeList: 'agent/employee-list',
    agentGroupBillsList: 'agent/group-bills-list',
    agentPaymentList: 'agent/payments-list',
    agentFetchGroupBillDetails: 'agent/group-bill-details',
    agentPastDueInvoices: 'agent/past-due-invoices',

    //Quick Enroll
    quickEnrollOrganizationDetails: 'quick-enroll/organization-details',
    quickEnrollEmployeeSave: 'quick-enroll/employee-save',
    quickEnrollEmployeeDetails: 'quick-enroll/employee-details',
    quickEnrollGetPlans: 'quick-enroll/plans',
    quickEnrollGetCoverage: 'quick-enroll/coverage',
    quickEnrollGetPaymentMethods: 'quick-enroll/payment-methods',
    quickEnrollDependentDelete: 'quick-enroll/dependent-delete',
    quickEnrollUpdateWaiveCoverage: 'quick-enroll/update-waive-coverage',
    quickEnrollDocumentSave: 'quick-enroll/document-save',
    quickEnrollDocumentList: 'quick-enroll/document-list',

    //Claims
    claimsList: 'employer/claims',
    claimsDetails: 'employer/claim-details',

    //Carrier Claims
    carrierClaimsList: 'carrier/claims',
    carrierClaimsDetails: 'carrier/claim-details'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
