import { EventEmitter, Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EventService } from './event.service';
import { Router } from '@angular/router';
import { customLoginPageDomain, colWidth, groupbillExcelSheet1, groupbillExcelSheet2, headerRowFontStyle, headerRowIndices, logoSize, rowDataIndex, userRoles, billingPortal, tobaccoValues, AMTDomains } from 'src/app/shared/models/constants';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as ExcelJS from 'exceljs';
import { config } from 'src/sites/config';
import { BehaviorSubject } from 'rxjs';
import * as DateRangePicker from 'daterangepicker';
import * as Moment from 'moment';

@Injectable({
    providedIn: 'root'
})

export class GlobalService {
    viewType = 'card';
    user: any = {};
    loggedInUser: any = {};
    month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    defaultRoute: any = false;
    roleName: any = '';
    menuConfigurations: any = {
        _menu_paymentMethods: false,
        _menu_invoices: false,
        _menu_payments: false,
        _menu_employeeList: false,
        _menu_billingGroups: false,
        _menu_employerInvoices: false,
        _menu_employerPayments: false
    }
    paymentActivity = {
        'paymentRequested': 'PAYMENT_REQUESTED',
        'paymentPageOpend': 'PAYMENT_PAGE_OPENED',
        'paymentCCSelected': 'CLICKED_CC_BUTTON',
        'paymentACHelected': 'CLICKED_ACH_BUTTON',
        'paymentCaptured': 'PAYMENT_DETAILS_CAPTRURED',
        'paymentVaultCreated': 'PAYMENT_VAULT_CREATED',
        'paymentDetailSend': 'PAYMENT_DETAILS_SENT_IES',
        'paymentSuccess': 'PAYMENT_SUCCESSFUL',
        'paymentFailed': 'PAYMENT_FAILED',

    };

    allPaymentMethods = {
        CC: "Credit Card",
        ACH: "ACH"
    };

    closeInvoiceModal = new EventEmitter<any>();
    closeBrochureModal = new EventEmitter<any>();
    invoiceDetailsService = new EventEmitter<any>();
    employeeDetailsService = new EventEmitter<any>();
    billingGroupDetailsService = new EventEmitter<any>();
    employerInvoiceDetailsService = new EventEmitter<any>();
    quickenrollPaymentMethodSave: EventEmitter<any> = new EventEmitter();
    quickenrollPaymentMethodList = new EventEmitter<any>();
    refreshData = new EventEmitter<any>();
    isTobaccoProduct = new BehaviorSubject(false);
    planDetails = new BehaviorSubject([]);

    constructor(private loaderState: NgxUiLoaderService, private eventService: EventService, private router: Router,
        private sanitizer: DomSanitizer) {
        setTimeout(() => { this.configureMenus() }, 1000);
    }

    getRole() {
        return this.user['role'];
    }

    hasRole(role: string) {
        return !!this.user['role'] && this.user['role'].toLowerCase() === role.toLowerCase();
    }

    isAdmin() {
        return this.user['isAdmin'] == 1;
    }

    loaderStart() {
        this.loaderState.start();
    }

    loaderStop() {
        this.loaderState.stopAll();
    }

    getInvoiceDetails(params) {
        this.invoiceDetailsService.emit(params);
    }

    getEmployeeDetails(params) {
        this.employeeDetailsService.emit(params);
    }

    getBillingGroupDetails(params) {
        this.billingGroupDetailsService.emit(params);
    }

    getEmployerInvoiceDetails(params) {
        this.employerInvoiceDetailsService.emit(params);
    }

    /**
    * This function used to refresh data.
    */
    getRefreshData() {
        this.refreshData.emit();
    }

    configureMenus() {
        if (this.user !== null) {
            if (this.getRole() == 'Employee') {
                this.menuConfigurations._menu_paymentMethods = true;
                this.menuConfigurations._menu_invoices = true;
                this.menuConfigurations._menu_payments = true;
                if (this.isAdmin()) {
                    this.getEmployerMenu();
                }
            } else if (this.getRole() == 'Employer') {
                this.getEmployerMenu();
            }
        }
        return this.menuConfigurations;
    }

    getEmployerMenu() {
        this.menuConfigurations._menu_paymentMethods = false;
        this.menuConfigurations._menu_invoices = false;
        this.menuConfigurations._menu_payments = false;
        this.menuConfigurations._menu_employeeList = true;
        this.menuConfigurations._menu_billingGroups = true;
        this.menuConfigurations._menu_employerInvoices = true;
        this.menuConfigurations._menu_employerPayments = true;
    }

    getPaymentActivityType(activity) {
        return !!this.paymentActivity[activity] ? this.paymentActivity[activity] : '';
    }

    getAuthorizationModules() {
        return this.user['modules'];
    }

    setView(data) {
        this.eventService.emit({
            name: 'routeData',
            value: data
        });
    }

    setViewType(view) {
        this.viewType = view;
    }

    getDefaultRoute() {
        const role = this.getRole();
        switch (role.toLowerCase()) {
            case 'employee':
                this.defaultRoute = '/member/invoices/';
                if (this.isAdmin()) {
                    this.defaultRoute = '/employer/member/';
                }
                break;

            case 'carrier':
                this.defaultRoute = '/carrier/employer';
                break;

            case 'organisation':
                this.defaultRoute = '/organization/employers';
                break;

            case 'agency':
                this.defaultRoute = '/agency/employers';
                break;

            default:
                this.defaultRoute = '/dashboard';
                break;
        }

        return this.defaultRoute;
    }

    getUserRoleName() {
        const role = this.getRole();
        switch (role.toLowerCase()) {
            case 'employee':
                this.roleName = 'employee';
                if (this.isAdmin()) {
                    this.roleName = 'employer';
                }
                break;
            case 'carrier':
                this.roleName = 'carrier';
                break;

            case 'organisation':
                this.roleName = 'organization';
                break;

            case 'agency':
                this.roleName = 'agency';
                break;

            case 'dependent':
                this.roleName = 'dependent';
                break;

            default:
                this.roleName = '';
                break;
        }
        return this.roleName;
    }

    isAdminEmployee(){
        return this.isAdmin() && this.getRole()?.toLowerCase() === userRoles.employee.toLowerCase();
    }

    b64toBlob(b64Data, contentType) {
        contentType = contentType || '';
        let sliceSize = 512;
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    formatAmount(amount) {
        if (!amount && amount !== 0) {
            return "$0.00";
        }

        if (Math.sign(amount) === -1) {
            amount = Math.abs(amount);
            return "-$" + parseFloat(amount).toFixed(2);
        }
        return "$" + parseFloat(amount).toFixed(2);
    }

    formatDate(date: string): string {
        return date ? new Date(date).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) : '';
    }

    /**
     * This function is used to navigate URL.
     * @param {string} url navigate url.
     */
    navigateURL(url) {
        let navigateurl;

        switch (this.getRole().toLowerCase()) {
            case userRoles.employer.toLowerCase():
                navigateurl = '/employer/' + url;
                break

            case userRoles.employee.toLowerCase():
                if (this.isAdmin()) {
                    navigateurl = '/employer/' + url;
                    break
                } else {
                    navigateurl = '/member/' + url;
                    break
                }

            case userRoles.dependent.toLowerCase():
                navigateurl = '/dependent/' + url;
                break

            default:
                break;
        }

        return this.router.navigate([navigateurl]);
    }


    /**
    * This function is used to parse HTML to plaintext.
    * @returns return parse string.
    */
    parseHtml(htmltext) {
        if (!htmltext)
            return ""

        const htmlContent: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(this.setLinksToOpenInNewTab(htmltext));
        return htmlContent ? htmlContent : "";

    }

    /**
    * This function is used to validate domain.
    * @returns return true / false.
    */
    validateDomain() {
        const currentDoamin = window.location.hostname;
        return customLoginPageDomain.includes(currentDoamin);
    }

    /**
    * IES-2375 : Update portal text for Clearwater
    * This function is used to validate billing portal domain.
    * @returns return true / false.
    */
    validateIsBillingPortalDomain() {
        const currentDoamin = window.location.hostname;
        return billingPortal.includes(currentDoamin);
    }

    /**
     * This function is used to check if the portal is AMT.
     * @returns {boolean}
     */
    validateIsAMTPortalDomain() {
        const currentDomain = window.location.hostname;
        return AMTDomains.includes(currentDomain);
    }

    dataTableInit(tableId) {
        let tableInvoices = $('#' + tableId).DataTable();
        if (tableInvoices) {
            tableInvoices.clear().draw();
            tableInvoices.destroy();
        }
    }
    /**
     * Export data to Excel file.
     * @param {objects} excelData representing the data to be exported.
     * @returns Promise
     */
    async downloadExcelFile(excelData) {
        const data = excelData.invoiceList;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(groupbillExcelSheet1);
        data.fileName = excelData.groupbillNo + "_" + Date.now()

        const headerDetails = {
            cityStateZip: excelData.cityStateZip,
            address: excelData.address,
            logoBuffer: !!excelData.logoBuffer ? excelData.logoBuffer : '',
            logo: excelData.logo
        }
        // Add logo and address
        await this.addLogoAndAddress(worksheet, workbook, headerDetails);

        // Add data list
        if (data) {
            const keys = Object.keys(data[0]);
            worksheet.insertRow(rowDataIndex, []);
            worksheet.addRow(keys);
            const headerRow = worksheet.getRow(rowDataIndex + 1);
            headerRow.eachCell((cell: any) => {
                cell.font = headerRowFontStyle;

                const columnIndex = cell.col - 1; // Convert 1-based index to 0-based index
                if (columnIndex < keys.length) {
                    const columnTitle = keys[columnIndex];
                    const column = worksheet.getColumn(cell.col);
                    column.width = columnTitle.length + 5; // Adjust multiplier as needed
                }
            });

            const rows = data.map(item => Object.values(item));
            worksheet.addRows(rows);

            /* IES-2164 : Portal: Incorrect Group Bill layout */
            const worksheet2 = workbook.addWorksheet(groupbillExcelSheet2);
            const groupsBillDetails = {
                ...headerDetails,
                rowData: excelData.groupsBillDetails

            };
            await this.groupbillDetailsSheet(worksheet2, workbook, groupsBillDetails);

            this.downloadExcel(await workbook.xlsx.writeBuffer(), `${data.fileName}.xlsx`);
        }
    }

    /**
     * Download Excel file.
     * @param {buffer} ArrayBuffer containing the Excel file data.
     * @param {string} Name of the Excel file.
     * @returns void
     */
    downloadExcel(buffer: ArrayBuffer, fileName: string): void {
        const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(data);
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
    }

    /**
     * Add logo and address to the worksheet.
     * @param {Object} worksheet - The Excel worksheet to which the logo and address will be added.
     * @param {Object} workbook - The Excel workbook to which the image will be added.
     * @param {Object} excelData - Object containing organization details including address and cityStateZip.
     * @returns A Promise resolving when the operation is complete.
     */
    async addLogoAndAddress(worksheet, workbook, excelData) {
        try {

            if (excelData.logoBuffer) {
                const imageSize = { ...logoSize };
                const logoImage = workbook.addImage({
                    base64: excelData.logoBuffer,
                    extension: 'png'
                });

                if (!excelData.logo)
                    imageSize.height = 550;

                worksheet.addImage(logoImage, {
                    tl: { col: 0, row: 1 },
                    ext: imageSize
                });
            }

            const cellA6 = worksheet.getCell('A6');
            cellA6.value = excelData.address;

            const cellA7 = worksheet.getCell('A7');
            cellA7.value = excelData.cityStateZip;
        } catch (error) {
            console.error('Error fetching image:', error);
            return undefined;
        }
    }


    /**
     * Add groupbill Details Sheet to the worksheet.
     * @param {Object} worksheet - The Excel worksheet to which the logo and address will be added.
     * @param {Object} workbook - The Excel workbook to which the image will be added.
     * @param {Object} excelData - Object containing organization details including address and cityStateZip.
     * @returns {Promise} A Promise resolving when the operation is complete.
     */
    async groupbillDetailsSheet(worksheet, workbook, excelData) {

        // Add logo and address to the worksheet
        await this.addLogoAndAddress(worksheet, workbook, excelData);
        worksheet.insertRow(rowDataIndex, []);

        const rows = excelData.rowData.map(item => Object.values(item));
        worksheet.addRows(rows);

        // Apply font style to header rows
        headerRowIndices.forEach(rowIndex => {
            const row = worksheet.getRow(rowIndex);
            row.eachCell((cell) => {
                cell.font = headerRowFontStyle;
            });
        });

        // Set column widths
        worksheet.columns.forEach(column => {
            column.width = (column._number == 1) ? colWidth.other : colWidth.amount;
        });

    }

    public convertDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    public convertDate2(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
    }

    public getEmployeeIdAccess() {
        let employeeData = { employeeId: this.user['userId'], isQuickEnroll: false };
        if (sessionStorage.getItem('employeeId')) {
            employeeData.employeeId = sessionStorage.getItem('employeeId');
            employeeData.isQuickEnroll = true;
        }
        return employeeData;
    }

    public hideHeaderOnQuickViewForm(isQuickEnroll, header = false) {
        if (isQuickEnroll) {
            let breadcrumb = $(".breadcrumb-section");
            breadcrumb.find("div.container").css({ 'display': 'none' });
            breadcrumb.css({ 'margin-top': '80px' });
            if (header) {
                breadcrumb.remove();
            }
        }
    }

    qePaymentMethodList(params) {
        this.quickenrollPaymentMethodList.emit(params);
    }
    /**
     * This function is used to return 4 digits number of selected payment method.
     * @param {string} number - The number.
     * @returns {string} - The number with last four digits visible.
     */
    getLastFourDigits(number) {
        let lastFourDigits = '';
        let numberToDisplay = '';
        lastFourDigits = number.substr(-4);
        numberToDisplay = number.replace(/./g, '*');
        numberToDisplay = numberToDisplay.slice(0, -4);
        numberToDisplay = numberToDisplay + '' + lastFourDigits;
        sessionStorage.setItem("numberToDisplay", numberToDisplay);
        return numberToDisplay;
    }

    /**
     * Checks if any value in array has tobacco set to "Y" or "y".
     * @param {Array} dependents Array.
     * @returns True if any object has tobacco set to "Y" or "y", otherwise false.
     */
    isTobacco(dependents): boolean {
        const found = dependents.find(dep => {
            return dep.dependentIsTobacco && dep.dependentIsTobacco.toLowerCase() === tobaccoValues.y.toLowerCase();
        });
        return found !== undefined;
    }

    /**
     * Calculates age based on the provided date of birth.
     * @param {string} dob - The date of birth in the format 'YYYY-MM-DD'.
     * @returns {number} - The calculated age.
     */
    calculateAge(dob) {
        const birthDate = Moment(dob);
        const today = Moment();
        return today.diff(birthDate, 'years');
    }

    /**
     * Retrieves the value of a CSS variable from the root element (:root).
     * @param {string} variableName - The name of the CSS variable (e.g., "--bs-text-primary-color").
     * @returns {string} - The value of the CSS variable.
     */
    getCssVariableValue(variableName: string): string {
        const rootStyles = getComputedStyle(document.documentElement);
        return rootStyles.getPropertyValue(variableName).trim();
    }

    /**
     * Apply custom border styles to a DataTable based on the provided data and border color.
     * @param {jQuery} table - The jQuery object representing the table.
     * @param {string} borderColor - The color to apply for table borders.
     * @param {Array} data - The data array used to determine if rows exist in the table.
     */
    applyTableCustomBorders(table, borderColor, data) {
        // If no data is available, remove all borders from table cells and return early
        if (!data || !data.length) {
            table.find('tr td').css('border', 'none');
            return;
        }

        table.find('td:last-child').css('border-right', 'none');
        table.find('td:first-child').css('border-left', 'none');
        table.find('th:last-child').css('border-right', 'none');
        table.find('th:first-child').css('border-left', 'none');
        table.find('td').css('border-bottom', `1px solid ${borderColor}`);
        table.find('tbody tr:last-child td').css('border-bottom', 'none');
    }

    /**
     * Determines the input type based on the element's attributes.
     * @param {HTMLElement} dataset - The dataset of the HTML element containing attributes.
     * @returns {string} - Returns 'dateRange', 'dropdown', or 'text' based on the attributes.
     */
    determineInputType = ({ dataset }) => {
        return dataset.daterange ? 'dateRange' : dataset.dropdown ? 'dropdown' : 'text';
    }

    /**
     * Creates a text input element for column search.
     * @param {string} headerText - The placeholder text to be displayed in the input field.
     * @param {Object} api - DataTables API instance to trigger the search.
     * @param {number} index - The column index to apply the search on.
     * @returns {HTMLElement} - A element representing the text input field.
     */
    createTextInput = (headerText, api, index) => {
        const input = $('<input>', {
            type: 'text',
            class: 'form-control column-search',
            placeholder: headerText,
        }).css({
            borderRadius: '5px',
            margin: '0',
            width: '100%',
            padding: '5px',
        });

        input.on('keyup change', function () {
            const searchValue = (this as HTMLInputElement).value; // Cast to HTMLInputElement
            api.column(index).search(searchValue).draw(); // Filters the DataTable
        });

        return input;
    };

    /**
     * Creates a dropdown element for column search using values from the provided object.
     * @param {string} headerText - The column name.
     * @param {Object} api - DataTables API instance to trigger the search.
     * @param {number} index - The column index to apply the search on.
     * @param {Object} dropdownObj - An object containing dropdown values.
     * @returns {HTMLElement} - A element representing the dropdown field.
     */
    createDropdown = (headerText, api, index, dropdownObj) => {
        const avoidSorting = ['frequency'];
        const select = $('<select>', {
            class: 'form-control column-search',
        }).css({
            borderRadius: '5px',
            margin: '0',
            width: '100%',
            padding: '5px',
            color: 'var(--text-extra-medium-gray)',
        });

        let sortedDropdownObj = dropdownObj;
        if (!avoidSorting.includes(headerText.toLowerCase())) {
            sortedDropdownObj = this.sortDropdown(dropdownObj)
        };

        // Adds a placeholder option
        select.append($('<option>', { value: '', text: `Select` }));

        // Populates the dropdown with values from the object
        Object.values(sortedDropdownObj).forEach((value: string) => {
            const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
            select.append($('<option>', { value, text: capitalizedValue }));
        });

        select.on('change', function () {
            const searchValue = (this as HTMLSelectElement).value || ''; // Cast to HTMLSelectElement
            api.column(index).search(searchValue).draw(); // Filters the DataTable on selection change
        });

        return select;
    };

    /**
     * Creates date range inputs (start and end) for column search.
     * @param {string} headerText - The placeholder text for the date inputs.
     * @param {Object} api - DataTables API instance to trigger the search.
     * @param {number} index - The column index to apply the search on.
     * @param {object} dataset - The element object.
     * @returns {HTMLElement} - A jQuery element containing the date range inputs.
     */
    createDateRangeInput = (headerText, api, index, { dataset }) => {
        const inputWrapper = $('<div>', { class: 'd-flex' }).css({ gap: '5px' });
        const id = headerText.replace(" ", "_") + '_' + index;
        let searchValue = '';
        const options = {
            showDropdowns: true,
            autoUpdateInput: false,
            locale: {
                format: 'MM/DD/YYYY',
                cancelLabel: 'Clear',
                applyLabel: 'Apply'
            },
            drops: dataset.daterangedrop ? dataset.daterangedrop : 'down'
        };
        const dateRangeInput = $('<input>', {
            id: id,
            class: 'form-control column-search',
            placeholder: `${headerText}`,
        }).css({
            borderRadius: '5px',
            margin: '0',
            width: '100%',
            padding: '5px'
        }).daterangepicker(options).on("apply.daterangepicker", function (e, picker) {
            picker.element.val(picker.startDate.format(picker.locale.format) + ' - ' + picker.endDate.format(picker.locale.format));
            picker.element.attr('title', picker.startDate.format(picker.locale.format) + ' - ' + picker.endDate.format(picker.locale.format));
            let startDate = new Date(picker.startDate.format(picker.locale.format));
            let endDate = new Date(picker.endDate.format(picker.locale.format));
            let currentDate = new Date(startDate);
            let dates = [];
            while (currentDate <= endDate) {
                let month = currentDate.getMonth() + 1;
                let date = currentDate.getDate();
                let monthWithZeroes = month > 9 ? month : '0' + month;
                let dateWithZeroes = date > 9 ? date : '0' + date;
                dates.push(monthWithZeroes + '-' + dateWithZeroes + '-' + currentDate.getFullYear());
                currentDate.setDate(currentDate.getDate() + 1);
            }
            searchValue = dates.length > 0 ? '^(' + dates.join('|') + ')$' : '';
            api.column(index).search(searchValue, true, false).draw();
        }).on("cancel.daterangepicker", function (e, picker) {
            picker.element.val('');
            picker.element.removeAttr('title');
            picker.startDate = Moment();
            picker.endDate = Moment();
            searchValue = '';
            api.column(index).search(searchValue, true, false).draw();
        }).on('input', function () {
            // Reset date range picker when input is manually cleared
            if ($(this).val() === '') {
                const picker = $(this).data('daterangepicker');
                picker.setStartDate(Moment());
                picker.setEndDate(Moment());
                $(this).removeAttr('title');
                searchValue = '';
                api.column(index).search(searchValue, true, false).draw();
            }
        });
        inputWrapper.append(dateRangeInput);
        return inputWrapper;
    };

    /**
     * Dynamically creates a search input row for each searchable column in the table.
     * @param {string} tableId - The ID of the table where the search inputs will be added.
     * @param {Object} api - DataTables API instance used to interact with the table.
     */
    addColumnSearch = (tableId, api) => {

        // Skip applying search functionality in mobile view
        if (window.innerWidth < 768) return;

        const isEmployee = (this.getRole().toLowerCase() == userRoles.employee.toLowerCase() && !this.isAdmin());
        const tableHeader = $(`${tableId} thead`);

        // Prevents adding multiple search rows
        if (tableHeader.find('.column-search-row').length > 0) return;

        // Get the background colorand border color from the first <th>
        const firstTh = tableHeader.find('tr:first-child th').first();
        let backgroundColor = firstTh.length ? firstTh.css('background-color') : 'var(--bs-secondary-color)'; // Fallback to 'transparent'
        let borderColor = firstTh.length ? firstTh.css('border-left-color') : 'var(--bs-table-border)'; // Use 'border-left-color' for consistency
        let borderBottom = backgroundColor;

        if (isEmployee) {
            backgroundColor = 'rgb(215, 215, 215)';
            borderColor = 'var(--bs-secondary-color)';
            borderBottom = borderColor;
        }

        const searchRow = $('<tr>', { class: 'column-search-row' });

        tableHeader.find('tr:first-child th').each((index, element) => {
            const { searchable, dropdown } = element.dataset;
            const th = $('<th>').css({
                backgroundColor: backgroundColor,
                border: 'none',
                borderLeft: `1px solid ${borderColor}`,
                borderTop: `1px solid ${borderColor}`,
                borderBottom: `1px solid ${borderBottom}`,
                borderWidth: isEmployee ? '1px' : '2px'
            });

            if (searchable !== 'false') {
                const headerText = $(element).text().trim();
                const inputType = this.determineInputType(element);

                let inputElement;

                // Generate appropriate input element based on the type
                switch (inputType) {
                    case 'dropdown':
                        const dropdownObj = JSON.parse(dropdown); // Parse the dropdown JSON
                        inputElement = this.createDropdown(headerText, api, index, dropdownObj);
                        break;
                    case 'dateRange':
                        inputElement = this.createDateRangeInput(headerText, api, index, element);
                        break;
                    default:
                        inputElement = this.createTextInput(headerText, api, index);
                }

                th.append(inputElement);
            }

            searchRow.append(th);

            // Apply custom styles to the global search input
            if (isEmployee)
                this.applyGlobalSearchStyles(api);
        });

        // Set border-radius to 0 for the first and last <th> elements
        searchRow.children('th:first-child').css({
            borderTopLeftRadius: '0',
            borderLeft: isEmployee ? '0' : `2px solid ${borderColor}`
        });

        searchRow.children('th:last-child').css({
            borderTopRightRadius: '0',
            borderRight: isEmployee ? '0' : `2px solid ${borderColor}`
        });

        tableHeader.append(searchRow); // Appends the search row to the table header
    };

    /**
     * Applies custom styles to the global search input of the DataTable.
     * @param {Object} api - DataTables API instance used to interact with the table.
     */
    applyGlobalSearchStyles = (api) => {
        $(api.table().container()).find('.dataTables_filter input').css({
            border: '1px solid var(--bs-secondary-color)', // Custom border color
            borderRadius: '5px',
            padding: '5px',
        });
    };


    /**
     * Sorts a dropdown input either as an array of strings or an object with key-value pairs.
     * @param dropdownObj - The input to be sorted, either an array of strings or an object with key-value pairs.
     * @returns The sorted version of the input, either as a sorted array or an object with sorted keys.
     */
    sortDropdown(dropdownObj) {
        let sortedDropdownObj;

        if (Array.isArray(dropdownObj)) {
            // Sort array alphabetically
            sortedDropdownObj = dropdownObj.sort((a, b) => a.localeCompare(b));
        } else {
            // Sort object entries alphabetically by keys
            const sortedEntries = Object.entries(dropdownObj).sort(([keyA], [keyB]) =>
                keyA.localeCompare(keyB)
            );
            sortedDropdownObj = Object.fromEntries(sortedEntries);
        }
        return sortedDropdownObj;
    }
    /**
     * This function checks the current page's protocol and ensures the provided URL uses the same protocol.
     * @param url - The URL to be validated and potentially modified.
     * @returns The modified URL with the correct protocol, or the original URL if no changes are needed.
     */
    validateProtocol(url): void {
        const isHttps = window.location.protocol === 'https:';
        return isHttps ? url.replace(/^http:/, 'https:') : url;
    }

    /**
     * Modifies anchor (<a>) tags within a given HTML string to open in a new tab.
     * @param {string} htmlText - The HTML content as a string.
     * @returns {string} - The modified HTML string with updated <a> tags.
     */
    setLinksToOpenInNewTab(htmlText) {
        const tempContainer = document.createElement('div');
        tempContainer.innerHTML = htmlText;

        const links = tempContainer.querySelectorAll('a');

        // Update each <a> tag to open in a new tab
        links.forEach(link => {
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'noopener noreferrer'); // Adds security enhancement
        });

        return tempContainer.innerHTML;
    }
    /**
     * Focuses the first invalid form field within the modal.
     * @param renderer - Renderer2 instance for safely manipulating the DOM
     */
    focusFirstInvalidField(renderer) {
        this.loaderStart();
        setTimeout(() => {
            const modalForm = document.querySelector('.paymentMethodForm');
            const invalidField = modalForm.querySelector('.error-border, input.ng-invalid') as HTMLElement;

            if (invalidField) {
                renderer.setAttribute(invalidField, 'tabindex', '-1')
                invalidField.focus();
                renderer.removeAttribute(invalidField, 'tabindex');
            }
            this.loaderStop();
        }, 100);

    }

}
